.textContainer {
  /* margin-top: 50px; */
  color: #fff;
}

.subText2 {
  color: #cbcbcb;
}

.mainContainer {
  background-image: url("../../assets/3d.png");
  background-repeat: no-repeat;
  background-size: 75%;
  background-position: 100% 100%;
}

.imageContainer {
  margin: 50px 100px;
}

.specialText {
  background-image: linear-gradient(90deg, #FF823F 0%, #FF823F);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.textAndImage {
  display: flex;
  align-items: flex-start;
}


@media (min-width: 660px) {
  .mainContainer {
    background-size:45%;
  }
}
