.textContainer {
  color: #fff;
}

.subText2 {
  color: #dddddd;
}

.mainContainer {
  
  background-repeat: no-repeat;
  background-position: left;
  background-size: contain;
}

.specialText {
  background-image: linear-gradient(90deg, #FF823F 0%, #FF823F);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
