.grow-card {
  background: linear-gradient(150deg, #171717 0%, #171717 100%);
  padding: 30px;
  border-image: linear-gradient(83deg, #B3F200 0%, #FF823F 100%) 1;
  border-width: 4px 0 0 0;
}

.grow-card:nth-child(2n) {
  border-image: linear-gradient(83deg, #FF823F 0%, #B3F200 100%) 1;
}

.card-icon {
  background: #f2f2f2;
  width: fit-content;
}
